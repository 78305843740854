import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';

export default function ListCatalog() {
  const scrollContainerRef = useRef(null);
  const [expandedItem, setExpandedItem] = useState(null);
  const [localAnimeData, setLocalAnimeData] = useState([]);
  const [animeDetails, setAnimeDetails] = useState([]);
  const [loadingLocalAnime, setLoadingLocalAnime] = useState(true);
  const [loadingAnimeDetails, setLoadingAnimeDetails] = useState(true);
  const [error, setError] = useState(null);
  const [showButtons, setShowButtons] = useState({ left: true, right: true });

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    const fetchLocalAnimeData = async () => {
      try {
        const response = await axios.get('https://player.aniplay.fun/anime');
        
        const filteredData = response.data.filter(anime => anime.uhd === 'true');
        setLocalAnimeData(filteredData);

        const ids = filteredData.map(anime => anime.id);
        const batchSize = 5; // Количество ID для запроса за раз
        const results = [];

        for (let i = 0; i < ids.length; i += batchSize) {
          const batchIds = ids.slice(i, i + batchSize).join(',');
          const { data } = await axios.get(`https://shikimori.one/api/animes?ids=${batchIds}`);
          results.push(...data);
          await delay(1000); // Задержка 1 секунда между запросами
        }

        setAnimeDetails(results);
        setLoadingLocalAnime(false);
        setLoadingAnimeDetails(false);
      } catch (error) {
        setError('Не удалось получить данные с сервера');
        setLoadingLocalAnime(false);
        setLoadingAnimeDetails(false);
      }
    };
  
    fetchLocalAnimeData();
  }, []);

  const handleScrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: -300,
        behavior: 'smooth',
      });
    }
  };

  const handleScrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: 300,
        behavior: 'smooth',
      });
    }
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    const startX = e.pageX - scrollContainerRef.current.offsetLeft;
    const scrollLeft = scrollContainerRef.current.scrollLeft;

    const handleMouseMove = (e) => {
      const x = e.pageX - scrollContainerRef.current.offsetLeft;
      const walk = (x - startX) * 1.2;
      scrollContainerRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleItemClick = (index) => {
    setExpandedItem(index === expandedItem ? null : index);
  };

  const updateButtonVisibility = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
      setShowButtons({
        left: scrollLeft > 0,
        right: scrollLeft < scrollWidth - clientWidth,
      });
    }
  };

  useEffect(() => {
    updateButtonVisibility();
    const currentContainer = scrollContainerRef.current;
    currentContainer.addEventListener('scroll', updateButtonVisibility);
    
    return () => {
      currentContainer.removeEventListener('scroll', updateButtonVisibility);
    };
  }, [localAnimeData]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (scrollContainerRef.current && !scrollContainerRef.current.contains(event.target)) {
        setExpandedItem(null);
      }
    };
  
    document.addEventListener('mousedown', handleOutsideClick);
  
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  function renderScoreWithSVG(score) {
    if (score >= 8) {
      return (
        <>
         <img src='../icons/top-left.png'></img>
          {score}
          <img src='../icons/top-right.png'></img>
        </>
      );
    } else {
      return (
        <>{score}</>
      );
    }
  }


  if (loadingLocalAnime || loadingAnimeDetails) return (
    <div className='list_catalog'>
        <div class="list_catalog_header">
          <span>Аниме в 4К</span>
          <span>Аниме в ультра качестве!</span>
        </div>
        <div className='left_button_1' onClick={handleScrollLeft}>
          <svg xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 24 24" fill="none">
              <path d="M14.2893 5.70708C13.8988 5.31655 13.2657 5.31655 12.8751 5.70708L7.98768 10.5993C7.20729 11.3805 7.2076 12.6463 7.98837 13.427L12.8787 18.3174C13.2693 18.7079 13.9024 18.7079 14.293 18.3174C14.6835 17.9269 14.6835 17.2937 14.293 16.9032L10.1073 12.7175C9.71678 12.327 9.71678 11.6939 10.1073 11.3033L14.2893 7.12129C14.6799 6.73077 14.6799 6.0976 14.2893 5.70708Z" fill="white"/>
          </svg>
        </div>
        <div className='right_button_1' onClick={handleScrollRight}>
          <svg xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 24 24" fill="none">
              <path d="M9.71069 18.2929C10.1012 18.6834 10.7344 18.6834 11.1249 18.2929L16.0123 13.4006C16.7927 12.6195 16.7924 11.3537 16.0117 10.5729L11.1213 5.68254C10.7308 5.29202 10.0976 5.29202 9.70708 5.68254C9.31655 6.07307 9.31655 6.70623 9.70708 7.09676L13.8927 11.2824C14.2833 11.6729 14.2833 12.3061 13.8927 12.6966L9.71069 16.8787C9.32016 17.2692 9.32016 17.9023 9.71069 18.2929Z" fill="white"/>
          </svg>
        </div>
        <div className='list_episode' ref={scrollContainerRef} onMouseDown={handleMouseDown}>
        {[
          0, 1, 2, 3, 4, 5, 6
        ].map((index) => (
          <div 
            key={index}
            className={`catalog_item ${expandedItem === index ? 'expanded' : ''}`}
            onClick={() => handleItemClick(index)}
          >
            <div className='item loadbar_item'>
              <div className='loading-bar'>
              </div>
            </div>
            <div class="overlay"></div>
            <div class="item_name loadbar_item_name">
              <div className='loading-bar'></div>
            </div>
          </div>
        ))}
        </div>
    </div>
  );

  return (
    <div className='list_catalog'>
      <div className='list_catalog_header'>
        <span>Аниме в 4К</span>
        <span>Аниме в ультра качестве!</span>
      </div>
      <div className="left_button_1" onClick={handleScrollLeft} style={{ opacity: showButtons.left ? 1 : 0 }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 24 24" fill="none">
          <path d="M14.2893 5.70708C13.8988 5.31655 13.2657 5.31655 12.8751 5.70708L7.98768 10.5993C7.20729 11.3805 7.2076 12.6463 7.98837 13.427L12.8787 18.3174C13.2693 18.7079 13.9024 18.7079 14.293 18.3174C14.6835 17.9269 14.6835 17.2937 14.293 16.9032L10.1073 12.7175C9.71678 12.327 9.71678 11.6939 10.1073 11.3033L14.2893 7.12129C14.6799 6.73077 14.6799 6.0976 14.2893 5.70708Z" fill="white"/>
        </svg>
      </div>
      <div className="right_button_1" onClick={handleScrollRight} style={{ opacity: showButtons.right ? 1 : 0 }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 24 24" fill="none">
          <path d="M9.71069 18.2929C10.1012 18.6834 10.7344 18.6834 11.1249 18.2929L16.0123 13.4006C16.7927 12.6195 16.7924 11.3537 16.0117 10.5729L11.1213 5.68254C10.7308 5.29202 10.0976 5.29202 9.70708 5.68254C9.31655 6.07307 9.31655 6.70623 9.70708 7.09676L13.8927 11.2824C14.2833 11.6729 14.2833 12.3061 13.8927 12.6966L9.71069 16.8787C9.32016 17.2692 9.32016 17.9023 9.71069 18.2929Z" fill="white"/>
        </svg>
      </div>
      <div className='list_episode' ref={scrollContainerRef} onMouseDown={handleMouseDown}>
        {localAnimeData.length > 0 ? localAnimeData.map((anime, index) => {
        const details = animeDetails.find(a => a.id === anime.id) || {}; // Получаем данные для каждого аниме
        
        return (
            <div 
            key={anime.id}
            className={`catalog_item ${expandedItem === index ? 'expanded' : ''}`}
            onClick={() => handleItemClick(index)}
            >
            <div className='item_hover'>
                <div className='item_hover_name'>{anime.title}</div>
                <div className='item_hover_genres'>
                {(anime.genres) || 'Нет жанров'}
                </div>
                <div className='item_hover_description'>
                    {anime.description || 'Описание не найдено'}
                </div>
                <div className='item_hover_butt_detail'>
                    <a href={`/play/search?title=${anime.title}`}>
                      <div className='item_butt_watch'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="10px" height="10px" viewBox="-0.5 0 7 7" version="1.1">
                              <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                  <g id="Dribbble-Light-Preview" transform="translate(-347.000000, -3766.000000)" fill="#000000">
                                  <g id="icons" transform="translate(56.000000, 160.000000)">
                                      <path d="M296.494737,3608.57322 L292.500752,3606.14219 C291.83208,3605.73542 291,3606.25002 291,3607.06891 L291,3611.93095 C291,3612.7509 291.83208,3613.26444 292.500752,3612.85767 L296.494737,3610.42771 C297.168421,3610.01774 297.168421,3608.98319 296.494737,3608.57322" id="play-[#1003]"></path>
                                  </g>
                                  </g>
                              </g>
                          </svg>
                          <span>{setLocalAnimeData ? 'Смотреть в 4К' : 'Смотреть'}</span>
                      </div>
                    </a>
                    <a href={`/anime/${anime.id}`}>
                      <div className='item_butt_detailed'>
                          <span>Подробнее</span>
                      </div>
                    </a>
                </div>
            </div>
            <div className={`item ${expandedItem === index ? 'expanded' : ''}`}>
                <div className='container_catalog_item_tag'>
                  <div className='item_tag' style={{ backgroundColor: '#ff3f3f', color: 'white', fontWeight: '500' }}>4K</div>
                  <div
                    className={`item_tag ${
                      anime.score >= 8 ? 'hard_score' : anime.score <= 7 ? 'low_score' : 'high_score'
                    }`}
                  >
                    {renderScoreWithSVG(anime.score)}
                  </div>
                </div>
                <img
                    src={`http://localhost:5000${anime.poster}`}
                    alt={details.russian || 'Загрузка...'}
                    className='item_image'
                    style={{ backgroundImage: `url('https://aniplay.fun/icons/fon_item.jpg')` }}
                />
                <div className='overlay'></div>
            </div>
            </div>
        );
        }) : <div></div>}
        {/* <div className='open_more_item_list'>
            <a href='#'>
              <svg xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 24 24" fill="none">
                  <path d="M13.2328 16.4569C12.9328 16.7426 12.9212 17.2173 13.2069 17.5172C13.4926 17.8172 13.9673 17.8288 14.2672 17.5431L13.2328 16.4569ZM19.5172 12.5431C19.8172 12.2574 19.8288 11.7827 19.5431 11.4828C19.2574 11.1828 18.7827 11.1712 18.4828 11.4569L19.5172 12.5431ZM18.4828 12.5431C18.7827 12.8288 19.2574 12.8172 19.5431 12.5172C19.8288 12.2173 19.8172 11.7426 19.5172 11.4569L18.4828 12.5431ZM14.2672 6.4569C13.9673 6.17123 13.4926 6.18281 13.2069 6.48276C12.9212 6.78271 12.9328 7.25744 13.2328 7.5431L14.2672 6.4569ZM19 12.75C19.4142 12.75 19.75 12.4142 19.75 12C19.75 11.5858 19.4142 11.25 19 11.25V12.75ZM5 11.25C4.58579 11.25 4.25 11.5858 4.25 12C4.25 12.4142 4.58579 12.75 5 12.75V11.25ZM14.2672 17.5431L19.5172 12.5431L18.4828 11.4569L13.2328 16.4569L14.2672 17.5431ZM19.5172 11.4569L14.2672 6.4569L13.2328 7.5431L18.4828 12.5431L19.5172 11.4569ZM19 11.25L5 11.25V12.75L19 12.75V11.25Z" fill="white"/>
              </svg>
            </a>
            <span>Показать все</span>
        </div> */}
      </div>
    </div>
  );
}
