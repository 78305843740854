import React, { Component } from 'react';
import Header from '../components/Header';
import AllOpenList from '../components/All=1';
import { Helmet } from "react-helmet";
import Footer from '../components/Footer';

export default class All extends Component {
    render() {
      return (
          <div>
            <Helmet>
              <title>Онгоинги</title>
            </Helmet>
            <Header />
            <AllOpenList />
            <div style={{height: '70px'}}></div>
            <Footer/>
          </div>
      )
    }
}