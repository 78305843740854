import React, { Component } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

export default class privacy extends Component {
  render() {
    return (
      <>
        <Header />
        <div className='privacy'>
          <h1>Политика конфиденциальности</h1>
          <p>
            Настоящая Политика конфиденциальности (далее — «Политика») определяет порядок обработки и защиты информации, предоставляемой Пользователями сайта <strong>Aniplay.fun</strong> (далее — «Сайт»).
          </p>

          <h2>1. Общие положения</h2>
          <ul>
            <li>1.1. Используя Сайт, Пользователь дает согласие на обработку своих персональных данных в соответствии с настоящей Политикой.</li>
            <li>1.2. Администрация сайта обеспечивает конфиденциальность предоставленных Пользователями данных.</li>
          </ul>

          <h2>2. Состав информации</h2>
          <p>Персональная информация, которую может собирать Сайт:</p>
          <ul>
            <li>2.1. Имя, адрес электронной почты, номер телефона и другие данные, предоставленные Пользователем при регистрации.</li>
            <li>2.2. Данные, автоматически собираемые при использовании Сайта: IP-адрес, cookies, данные о браузере и устройстве.</li>
          </ul>

          <h2>3. Цели обработки данных</h2>
          <ul>
            <li>3.1. Идентификация Пользователя при входе в личный кабинет.</li>
            <li>3.2. Предоставление доступа к персонализированному контенту.</li>
            <li>3.3. Улучшение работы Сайта и предоставляемых услуг.</li>
            <li>3.4. Отправка уведомлений, новостей и предложений (с согласия Пользователя).</li>
          </ul>

          <h2>4. Условия предоставления доступа к данным</h2>
          <ul>
            <li>4.1. Администрация не передает персональные данные Пользователя третьим лицам, за исключением случаев, предусмотренных законодательством РФ.</li>
            <li>4.2. Данные могут быть переданы уполномоченным органам по их запросу в рамках закона.</li>
          </ul>

          <h2>5. Защита данных</h2>
          <ul>
            <li>5.1. Администрация предпринимает необходимые меры для защиты персональных данных Пользователей от несанкционированного доступа, изменения, раскрытия или уничтожения.</li>
            <li>5.2. Используются современные технологии шифрования и системы защиты информации.</li>
          </ul>

          <h2>6. Права Пользователя</h2>
          <ul>
            <li>6.1. Пользователь имеет право запросить удаление своих данных, обратившись к Администрации.</li>
            <li>6.2. Пользователь может отозвать согласие на обработку данных, прекратив использование Сайта.</li>
          </ul>

          <h2>7. Изменения в Политике</h2>
          <ul>
            <li>7.1. Администрация оставляет за собой право изменять настоящую Политику в любое время без уведомления Пользователей.</li>
            <li>7.2. Новая редакция Политики вступает в силу с момента ее публикации на Сайте.</li>
          </ul>
        </div>
        <Footer />
      </>
    );
  }
}
