import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function Auth() {
  const navigate = useNavigate();
  const [login, setLogin] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [showAuthOptions, setShowAuthOptions] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/welcome');
    }
  }, [navigate]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
  
    // Регулярные выражения для проверки email, пароля и login
    const emailPattern = /^[a-zA-Z0-9._@-]*$/;
    const passwordPattern = /^[^<>]*$/;
    const loginPattern = /^[a-zA-Z0-9]*$/; // только буквы и цифры для login
  
    // Проверяем поле email
    if (name === 'email' && !emailPattern.test(value)) {
      return;
    }
  
    // Проверяем поле password
    if (name === 'password' && !passwordPattern.test(value)) {
      return;
    }
  
    // Проверяем поле login
    if (name === 'login') {
      // Ограничение длины до 8 символов и проверка по шаблону
      if (value.length > 8 || !loginPattern.test(value)) {
        return;
      }
      setLogin(value);
      return;
    }
  
    // Если ввод корректный, обновляем значение
    if (name === 'email') setEmail(value);
    if (name === 'password') setPassword(value);
  };

  const showMessage = (messageType, message) => {
    if (messageType === 'error') {
      // Убедимся, что message — строка
      setErrorMessage(typeof message === 'string' ? message : JSON.stringify(message));
    } else {
      setSuccessMessage(typeof message === 'string' ? message : JSON.stringify(message));
    }
    setIsVisible(true);
    setTimeout(() => {
      setIsVisible(false);
    }, 10000);
  };

  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const validatePassword = (password) => /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(password);

  const handleRegister = async () => {
    if (!login || !email || !password) {
      showMessage('error', 'Все поля должны быть заполнены');
      return;
    }
    if (!validateEmail(email)) {
      showMessage('error', 'Введите действительный адрес электронной почты');
      return;
    }
    if (!validatePassword(password)) {
      showMessage('error', 'Пароль должен содержать как минимум 8 символов, включая буквы и цифры');
      return;
    }
    if (password.includes(login) || password.includes(email.split('@')[0])) {
      showMessage('error', 'Пароль не должен содержать имя пользователя или email');
      return;
    }
    if (!agreeTerms) {
      showMessage('error', 'Вы должны согласиться с условиями использования');
      return;
    }

    try {
      const response = await axios.post('https://api.aniplay.fun/register', { login, email, password });
      showMessage('success', 'Пользователь зарегистрирован!');
      setShowAuthOptions(true);
      setIsLogin(false);
      setLogin('');
      setEmail('');
      setPassword('');
      setAgreeTerms(false);
    } catch (error) {
      // Обработка ошибки: предполагается, что сервер возвращает строку или объект с полем error/message
      const message = error.response?.data || 'Ошибка при регистрации';
      // Если сервер отправляет объект с полем error или message, извлекаем его
      const errorMsg = typeof message === 'string' ? message : message.error || message.message || 'Ошибка при регистрации';
      showMessage('error', errorMsg);
    }
  };

  const handleLogin = async () => {
    if (!email || !password) {
      showMessage('error', 'Все поля должны быть заполнены');
      return;
    }
    try {
      const response = await axios.post('https://api.aniplay.fun/login', { email, password });
      localStorage.setItem('token', response.data.token);
      showMessage('success', 'Успешный вход!');
      navigate('/welcome');
    } catch (error) {
      // Обработка ошибки: предполагается, что сервер возвращает строку или объект с полем error/message
      const message = error.response?.data || 'Ошибка при входе';
      // Если сервер отправляет объект с полем error или message, извлекаем его
      const errorMsg = typeof message === 'string' ? message : message.error || message.message || 'Ошибка при входе';
      showMessage('error', errorMsg);
    }
  };

  const handleBackToAuthOptions = () => {
    setShowAuthOptions(true);
    setIsLogin(false);
  };

  return (
    <div className='new_auth_user'>
      <div className='placeholder-container'>
        <div className='container_span_logo_name'>
          <div className='top_container_span_logo_name'>Добро пожаловать на </div>
          <span>ANIPLAY<span style={{color: '#ff4a6a'}}>.FUN</span></span>
          <div className='bottom_container_span_logo_name'>
            <span>для любителей аниме</span>
          </div>
          <div className='link_bottom_container_span_logo_name'>
            <svg xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" viewBox="0 0 32 32" fill="none">
              <circle cx="16" cy="16" r="14" fill="url(#paint0_linear_87_7225)"/>
              <path d="M22.9866 10.2088C23.1112 9.40332 22.3454 8.76755 21.6292 9.082L7.36482 15.3448C6.85123 15.5703 6.8888 16.3483 7.42147 16.5179L10.3631 17.4547C10.9246 17.6335 11.5325 17.541 12.0228 17.2023L18.655 12.6203C18.855 12.4821 19.073 12.7665 18.9021 12.9426L14.1281 17.8646C13.665 18.3421 13.7569 19.1512 14.314 19.5005L19.659 22.8523C20.2585 23.2282 21.0297 22.8506 21.1418 22.1261L22.9866 10.2088Z" fill="white"/>
              <defs>
              <linearGradient id="paint0_linear_87_7225" x1="16" y1="2" x2="16" y2="30" gradientUnits="userSpaceOnUse">
              <stop stop-color="#37BBFE"/>
              <stop offset="1" stop-color="#007DBB"/>
              </linearGradient>
              </defs>
            </svg>
            Мы в Telegram
          </div>
        </div>

        {errorMessage && (
          <div className={`alert error ${isVisible ? 'fade-in' : 'fade-out'}`}>
            <span className="alert_message error">{errorMessage}</span>
          </div>
        )}
        {successMessage && (
          <div className={`alert success ${isVisible ? 'fade-in' : 'fade-out'}`}>
            <span className="alert_message success">{successMessage}</span>
          </div>
        )}

        {showAuthOptions ? (
          <div className='type_auth fade-in'>
            <span>Выберите способ авторизации</span>
            <div className='variables'>
              <div className='aniplay_auth' onClick={() => { setShowAuthOptions(false); setIsLogin(true); }}>
                <p>Войти</p>
              </div>
              <div className='bottom_line_auth'></div>
              <div className='aniplay_auth_2' onClick={() => { setShowAuthOptions(false); setIsLogin(false); }}>
                <p>Зарегистрироваться</p>
              </div>
            </div>
          </div>
        ) : isLogin ? (
          <div className='login_type_auth fade-in'>
            <span>Авторизация</span>
            <input
              className='login_email'
              type='text'
              name='email'
              value={email}
              onChange={handleInputChange}
              placeholder="Введите email"
            />
            <div className='password-container'>
              <input
                className='password_email'
                type={showPassword ? 'text' : 'password'}
                name='password'
                value={password}
                onChange={handleInputChange}
                placeholder="Введите пароль"
              />
              <div type='button' onClick={togglePasswordVisibility}>
                {showPassword ? (
                    <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 24 24" fill="none">
                      <path d="M2.99902 3L20.999 21M9.8433 9.91364C9.32066 10.4536 8.99902 11.1892 8.99902 12C8.99902 13.6569 10.3422 15 11.999 15C12.8215 15 13.5667 14.669 14.1086 14.133M6.49902 6.64715C4.59972 7.90034 3.15305 9.78394 2.45703 12C3.73128 16.0571 7.52159 19 11.9992 19C13.9881 19 15.8414 18.4194 17.3988 17.4184M10.999 5.04939C11.328 5.01673 11.6617 5 11.9992 5C16.4769 5 20.2672 7.94291 21.5414 12C21.2607 12.894 20.8577 13.7338 20.3522 14.5" stroke="#ffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 24 24" fill="none">
                      <path d="M15.0007 12C15.0007 13.6569 13.6576 15 12.0007 15C10.3439 15 9.00073 13.6569 9.00073 12C9.00073 10.3431 10.3439 9 12.0007 9C13.6576 9 15.0007 10.3431 15.0007 12Z" stroke="#ffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M12.0012 5C7.52354 5 3.73326 7.94288 2.45898 12C3.73324 16.0571 7.52354 19 12.0012 19C16.4788 19 20.2691 16.0571 21.5434 12C20.2691 7.94291 16.4788 5 12.0012 5Z" stroke="#ffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  )}
              </div>
            </div>
            <div className='butt_register' onClick={handleLogin}>Войти</div>
            <div className='butt_back' onClick={handleBackToAuthOptions}>Назад</div>
          </div>
        ) : (
          <div className='register_type_auth fade-in'>
            <span>Регистрация</span>
            <input
              className='login_email'
              type='text'
              name='login'
              value={login}
              onChange={handleInputChange}
              placeholder="Введите ваше имя"
            />
            <input
              className='login_email'
              type='text'
              name='email'
              value={email}
              onChange={handleInputChange}
              placeholder="Введите email"
            />
            <div className='password-container'>
              <input
                className='password_email'
                type={showPassword ? 'text' : 'password'}
                name='password'
                value={password}
                onChange={handleInputChange}
                placeholder="Введите пароль"
              />
              <div type='button' onClick={togglePasswordVisibility}>
                {showPassword ? (
                  <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 24 24" fill="none">
                    <path d="M2.99902 3L20.999 21M9.8433 9.91364C9.32066 10.4536 8.99902 11.1892 8.99902 12C8.99902 13.6569 10.3422 15 11.999 15C12.8215 15 13.5667 14.669 14.1086 14.133M6.49902 6.64715C4.59972 7.90034 3.15305 9.78394 2.45703 12C3.73128 16.0571 7.52159 19 11.9992 19C13.9881 19 15.8414 18.4194 17.3988 17.4184M10.999 5.04939C11.328 5.01673 11.6617 5 11.9992 5C16.4769 5 20.2672 7.94291 21.5414 12C21.2607 12.894 20.8577 13.7338 20.3522 14.5" stroke="#ffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 24 24" fill="none">
                    <path d="M15.0007 12C15.0007 13.6569 13.6576 15 12.0007 15C10.3439 15 9.00073 13.6569 9.00073 12C9.00073 10.3431 10.3439 9 12.0007 9C13.6576 9 15.0007 10.3431 15.0007 12Z" stroke="#ffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M12.0012 5C7.52354 5 3.73326 7.94288 2.45898 12C3.73324 16.0571 7.52354 19 12.0012 19C16.4788 19 20.2691 16.0571 21.5434 12C20.2691 7.94291 16.4788 5 12.0012 5Z" stroke="#ffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                )}
              </div>
            </div>
            <label className='checkbox'>
              <input
                type='checkbox'
                checked={agreeTerms}
                onChange={() => setAgreeTerms(!agreeTerms)}
              />
              Я соглашаюсь с условиями использования
            </label>
            <div className='butt_register' onClick={handleRegister}>Зарегистрироваться</div>
            <div className='butt_back' onClick={handleBackToAuthOptions}>Назад</div>
          </div>
        )}
      </div>
    </div>
  );
}
