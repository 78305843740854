import React, { Component } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

export default class PublicPage  extends Component {
  render() {
    return (
      <>
        <Header />
        <div className='public'>
          <h1>Публичная оферта</h1>
          <p>
            Настоящий документ является официальным предложением (публичной офертой) владельца сайта <strong>Aniplay.fun</strong> 
            (далее — «Администрация») для любых физических лиц, заключить договор об использовании сайта на условиях, указанных ниже.
          </p>

          <h2>1. Общие положения</h2>
          <ul>
            <li>1.1. Настоящая оферта считается акцептованной Пользователем с момента начала использования сайта.</li>
            <li>1.2. Использование сайта возможно только на условиях полного согласия с настоящей офертой.</li>
          </ul>

          <h2>2. Предмет оферты</h2>
          <ul>
            <li>2.1. Администрация предоставляет Пользователю доступ к материалам сайта, включая текстовые, графические, аудиовизуальные и другие данные.</li>
            <li>2.2. Услуги сайта предоставляются бесплатно, если иное не указано отдельно.</li>
          </ul>

          <h2>3. Права и обязанности сторон</h2>
          <p><strong>3.1. Обязанности Пользователя:</strong></p>
          <ul>
            <li>Использовать сайт в соответствии с его назначением и законодательством РФ.</li>
            <li>Не публиковать контент, нарушающий права третьих лиц или законодательство.</li>
            <li>Своевременно сообщать о выявленных проблемах в работе сайта.</li>
          </ul>
          <p><strong>3.2. Права Пользователя:</strong></p>
          <ul>
            <li>Получать доступ к материалам сайта в пределах, установленных настоящей офертой.</li>
            <li>Свободно прекращать использование сайта в любое время.</li>
          </ul>

          <p><strong>3.3. Права Администрации:</strong></p>
          <ul>
            <li>Изменять условия использования сайта без предварительного уведомления Пользователей.</li>
            <li>Удалять или ограничивать доступ к материалам, нарушающим настоящую оферту или законодательство.</li>
          </ul>

          <h2>4. Ответственность</h2>
          <ul>
            <li>4.1. Пользователь несет ответственность за нарушение условий настоящей оферты и действующего законодательства.</li>
            <li>4.2. Администрация не несет ответственности за ущерб, причиненный в результате использования или невозможности использования сайта.</li>
          </ul>

          <h2>5. Заключительные положения</h2>
          <ul>
            <li>5.1. Настоящая оферта вступает в силу с момента ее акцепта Пользователем и действует до момента прекращения использования сайта.</li>
            <li>5.2. Все возникающие споры подлежат урегулированию в соответствии с законодательством РФ.</li>
          </ul>
        </div>
        <Footer />
      </>
    );
  }
}
