import React, { Component } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'

export default class agreement extends Component {
  render() {
    return (
        <>
         <Header />
         <div className='agreement'>
            <h1>Пользовательское соглашение</h1>
            <p>
                Настоящее Пользовательское Соглашение (далее — «Соглашение») регулирует взаимоотношения между владельцем сайта<br/>
                <strong>Aniplay.fun</strong> (далее — «Администрация») и пользователями ресурса (далее — «Пользователь»).
            </p>
            <p>
                Используя сайт, Пользователь подтверждает согласие с условиями данного Соглашения. В случае несогласия с
                условиями, Пользователь обязуется прекратить использование ресурса.
            </p>

            <h2>1. Термины и определения</h2>
            <ul>
                <li><strong>Посетитель</strong> — лицо, которое получает доступ к информации на сайте.</li>
                <li><strong>Пользователь</strong> — лицо, прошедшее регистрацию на сайте и получившее учетную запись.</li>
                <li><strong>Сайт</strong> — интернет-ресурс, доступный по адресу <a href="https://aniplay.fun">aniplay.fun</a>, включая все поддомены и связанные сервисы.</li>
                <li><strong>Учетная запись</strong> — данные, предоставленные Пользователем для авторизации и идентификации.</li>
                <li><strong>Контент</strong> — информация, размещаемая на сайте, включая текстовые и аудиовизуальные материалы.</li>
            </ul>

            <h2>2. Предмет Соглашения</h2>
            <ul>
                <li>Настоящее Соглашение определяет правила использования сайта, включая доступ к размещенному Контенту.</li>
                <li>Регистрация на сайте означает полное согласие с условиями данного Соглашения.</li>
            </ul>

            <h2>3. Права и обязанности Пользователя</h2>
            <p><strong>3.1. Пользователь обязуется:</strong></p>
            <ul>
                <li>Указывать достоверные данные при регистрации.</li>
                <li>Не размещать материалы, нарушающие законодательство РФ или права третьих лиц.</li>
                <li>Не использовать автоматизированные инструменты для взаимодействия с сайтом без разрешения.</li>
            </ul>
            <p><strong>3.2. Пользователь имеет право:</strong></p>
            <ul>
                <li>Размещать Контент, соблюдая условия Соглашения.</li>
                <li>Удалить свою учетную запись, обратившись к Администрации.</li>
            </ul>

            <h2>4. Права и обязанности Администрации</h2>
            <p><strong>4.1. Администрация вправе:</strong></p>
            <ul>
                <li>Изменять условия Соглашения без предварительного уведомления Пользователя.</li>
                <li>Удалять учетные записи и Контент, нарушающие условия Соглашения.</li>
                <li>Ограничивать доступ к сайту в случае нарушений.</li>
            </ul>
            <p><strong>4.2. Администрация не несет ответственности за:</strong></p>
            <ul>
                <li>Достоверность информации, размещенной Пользователями.</li>
                <li>Возможные перебои в работе сайта.</li>
                <li>Убытки, вызванные использованием сайта.</li>
            </ul>

            <h2>5. Контент и авторские права</h2>
            <ul>
                <li>Пользователь гарантирует, что обладает всеми необходимыми правами на размещаемый Контент.</li>
                <li>Размещая Контент, Пользователь соглашается с его использованием в рамках сайта.</li>
            </ul>

            <h2>6. Ответственность</h2>
            <ul>
                <li>Пользователь несет ответственность за размещение информации, нарушающей законодательство.</li>
                <li>Администрация имеет право передавать данные Пользователя по запросу уполномоченных органов в случаях, предусмотренных законодательством.</li>
            </ul>

            <h2>7. Заключительные положения</h2>
            <ul>
                <li>Настоящее Соглашение вступает в силу с момента его принятия Пользователем.</li>
                <li>Администрация оставляет за собой право изменять условия Соглашения. Продолжение использования сайта означает принятие изменений.</li>
            </ul>
        </div>
        <Footer/>
        </>
    )
  }
}
