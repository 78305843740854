import React, { Component } from 'react'
import Header from '../components/Header';
import { Helmet } from "react-helmet";
import Footer from '../components/Footer';
import GenreAll from '../components/GenreAll';


export default class genre extends Component {
  render() {
    return (
      <div>
        <Helmet>
            <title></title>
        </Helmet>
        <Header />
        <GenreAll />
        <div style={{height: '70px'}}></div>
        <Footer/>
      </div>
    )
  }
}
