import React, { useEffect, useRef, useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import axios from 'axios';

// GraphQL запрос для получения аниме со статусом "анонс"
const GET_ANIME_ANNOUNCEMENTS = gql`
  query GetAnimeAnnouncements {
    animes(kind: "tv,tv_24,tv_13", status: "ongoing,latest", limit: 15 ) {
      id
      name
      russian
      score
      episodes
      poster {
        originalUrl
      }
      genres {
        russian
        kind
      }
      description
    }
  }
`;

export default function ListCatalog() {
  const scrollContainerRef = useRef(null);
  const [expandedItem, setExpandedItem] = useState(null);
  const [serverData, setServerData] = useState([]);
  const { loading, error, data } = useQuery(GET_ANIME_ANNOUNCEMENTS);
  const [showButtons, setShowButtons] = useState({ left: true, right: true });

  // Обновлённая функция для получения данных с вашего сервера
  const fetchServerData = async () => {
    try {
      const response = await axios.get('http://localhost:5000/anime?uhd=true');
      setServerData(response.data);
    } catch (err) {
      console.error('Ошибка при получении данных с сервера:', err);
    }
  };

  useEffect(() => {
    fetchServerData();
  }, []);

  const handleScrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: -300,
        behavior: 'smooth',
      });
    }
  };

  const handleScrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: 300,
        behavior: 'smooth',
      });
    }
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    const startX = e.pageX - scrollContainerRef.current.offsetLeft;
    const scrollLeft = scrollContainerRef.current.scrollLeft;

    const handleMouseMove = (e) => {
      const x = e.pageX - scrollContainerRef.current.offsetLeft;
      const walk = (x - startX) * 1.0;
      scrollContainerRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleItemClick = (index) => {
    setExpandedItem(index === expandedItem ? null : index);
  };

  const cleanDescription = (description) => {
    return description ? description.replace(/\[.*?\]/g, '') : 'Нет описания';
  };

  // Функция для проверки наличия аниме в серверных данных
  const findAnimeOnServer = (id) => {
    return serverData.find((anime) => anime.id === Number(id));
  };

  // Функция для получения жанров в нужном формате
  const getGenres = (anime) => {
    if (Array.isArray(anime.genres) && anime.genres.length > 0) {
      return anime.genres
        .filter((genre) => genre.kind === 'genre' || genre.kind === 'demographic')
        .map((genre) => genre.russian)
        .join(', ');
    } else if (typeof anime.genres === 'string' && anime.genres.trim() !== '') {
      return anime.genres;
    } else {
      return 'Нет жанров';
    }
  };

  const updateButtonVisibility = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
      setShowButtons({
        left: scrollLeft > 0,
        right: scrollLeft < scrollWidth - clientWidth,
      });
    }
  };

  useEffect(() => {
    updateButtonVisibility();
    const currentContainer = scrollContainerRef.current;
    currentContainer.addEventListener('scroll', updateButtonVisibility);
    
    return () => {
      currentContainer.removeEventListener('scroll', updateButtonVisibility);
    };
  }, [serverData]);

  useEffect(() => {
    // Функция для закрытия всех элементов, если клик произошел за пределами list_catalog
    const handleOutsideClick = (event) => {
      if (scrollContainerRef.current && !scrollContainerRef.current.contains(event.target)) {
        setExpandedItem(null);
      }
    };
  
    // Добавляем слушатель событий для кликов вне элемента list_catalog
    document.addEventListener('mousedown', handleOutsideClick);
  
    return () => {
      // Удаляем слушатель при размонтировании компонента
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  function renderScoreWithSVG(score) {
    if (score >= 8) {
      return (
        <>
         <img src='../icons/top-left.png'></img>
          {score}
          <img src='../icons/top-right.png'></img>
        </>
      );
    } else {
      return (
        <>{score}</>
      );
    }
  }

  if (loading) return (
    <div className='list_catalog'>
        <div class="list_catalog_header">
          <span>Сейчас популярно</span>
          <span>Собраны самые популярные релизы за последнее время!</span>
        </div>
        <div className='left_button_1' onClick={handleScrollLeft}>
          <svg xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 24 24" fill="none">
              <path d="M14.2893 5.70708C13.8988 5.31655 13.2657 5.31655 12.8751 5.70708L7.98768 10.5993C7.20729 11.3805 7.2076 12.6463 7.98837 13.427L12.8787 18.3174C13.2693 18.7079 13.9024 18.7079 14.293 18.3174C14.6835 17.9269 14.6835 17.2937 14.293 16.9032L10.1073 12.7175C9.71678 12.327 9.71678 11.6939 10.1073 11.3033L14.2893 7.12129C14.6799 6.73077 14.6799 6.0976 14.2893 5.70708Z" fill="white"/>
          </svg>
        </div>
        <div className='right_button_1' onClick={handleScrollRight}>
          <svg xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 24 24" fill="none">
              <path d="M9.71069 18.2929C10.1012 18.6834 10.7344 18.6834 11.1249 18.2929L16.0123 13.4006C16.7927 12.6195 16.7924 11.3537 16.0117 10.5729L11.1213 5.68254C10.7308 5.29202 10.0976 5.29202 9.70708 5.68254C9.31655 6.07307 9.31655 6.70623 9.70708 7.09676L13.8927 11.2824C14.2833 11.6729 14.2833 12.3061 13.8927 12.6966L9.71069 16.8787C9.32016 17.2692 9.32016 17.9023 9.71069 18.2929Z" fill="white"/>
          </svg>
        </div>
        <div className='list_episode' ref={scrollContainerRef} onMouseDown={handleMouseDown}>
        {[
          0, 1, 2, 3, 4, 5, 6
        ].map((index) => (
          <div 
            key={index}
            className={`catalog_item ${expandedItem === index ? 'expanded' : ''}`}
            onClick={() => handleItemClick(index)}
          >
            <div className='item loadbar_item'>
              <div className='loading-bar'></div>
            </div>
            <div class="overlay"></div>
            <div class="item_name loadbar_item_name">
              <div className='loading-bar'></div>
            </div>
          </div>
        ))}
        </div>
    </div>
  );

  if (error) return (
    <div className='list_catalog'>
        <div class="list_catalog_header">
          <span>Сейчас популярно</span>
          <span>Собраны самые популярные релизы за последнее время!</span>
        </div>
        <div className='left_button_1' onClick={handleScrollLeft}>
          <svg xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 24 24" fill="none">
              <path d="M14.2893 5.70708C13.8988 5.31655 13.2657 5.31655 12.8751 5.70708L7.98768 10.5993C7.20729 11.3805 7.2076 12.6463 7.98837 13.427L12.8787 18.3174C13.2693 18.7079 13.9024 18.7079 14.293 18.3174C14.6835 17.9269 14.6835 17.2937 14.293 16.9032L10.1073 12.7175C9.71678 12.327 9.71678 11.6939 10.1073 11.3033L14.2893 7.12129C14.6799 6.73077 14.6799 6.0976 14.2893 5.70708Z" fill="white"/>
          </svg>
        </div>
        <div className='right_button_1' onClick={handleScrollRight}>
          <svg xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 24 24" fill="none">
              <path d="M9.71069 18.2929C10.1012 18.6834 10.7344 18.6834 11.1249 18.2929L16.0123 13.4006C16.7927 12.6195 16.7924 11.3537 16.0117 10.5729L11.1213 5.68254C10.7308 5.29202 10.0976 5.29202 9.70708 5.68254C9.31655 6.07307 9.31655 6.70623 9.70708 7.09676L13.8927 11.2824C14.2833 11.6729 14.2833 12.3061 13.8927 12.6966L9.71069 16.8787C9.32016 17.2692 9.32016 17.9023 9.71069 18.2929Z" fill="white"/>
          </svg>
        </div>
        <div className='list_episode' ref={scrollContainerRef} onMouseDown={handleMouseDown}>
        {[
          0, 1, 2, 3, 4, 5, 6
        ].map((index) => (
          <div 
            key={index}
            className={`catalog_item ${expandedItem === index ? 'expanded' : ''}`}
            onClick={() => handleItemClick(index)}
          >
            <div className='item loadbar_item'>
              <div className='loading-bar'></div>
            </div>
            <div class="overlay"></div>
            <div class="item_name loadbar_item_name">
              <div className='loading-bar'></div>
            </div>
          </div>
        ))}
        </div>
    </div>
  );

  return (
    <div className="list_catalog">
      <div className="list_catalog_header">
        <span>Сейчас популярно</span>
        <span>Собраны самые популярные релизы за последнее время!</span>
      </div>
      <div className="left_button_1" onClick={handleScrollLeft} style={{ opacity: showButtons.left ? 1 : 0 }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 24 24" fill="none">
          <path d="M14.2893 5.70708C13.8988 5.31655 13.2657 5.31655 12.8751 5.70708L7.98768 10.5993C7.20729 11.3805 7.2076 12.6463 7.98837 13.427L12.8787 18.3174C13.2693 18.7079 13.9024 18.7079 14.293 18.3174C14.6835 17.9269 14.6835 17.2937 14.293 16.9032L10.1073 12.7175C9.71678 12.327 9.71678 11.6939 10.1073 11.3033L14.2893 7.12129C14.6799 6.73077 14.6799 6.0976 14.2893 5.70708Z" fill="white"/>
        </svg>
      </div>
      <div className="right_button_1" onClick={handleScrollRight} style={{ opacity: showButtons.right ? 1 : 0 }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 24 24" fill="none">
          <path d="M9.71069 18.2929C10.1012 18.6834 10.7344 18.6834 11.1249 18.2929L16.0123 13.4006C16.7927 12.6195 16.7924 11.3537 16.0117 10.5729L11.1213 5.68254C10.7308 5.29202 10.0976 5.29202 9.70708 5.68254C9.31655 6.07307 9.31655 6.70623 9.70708 7.09676L13.8927 11.2824C14.2833 11.6729 14.2833 12.3061 13.8927 12.6966L9.71069 16.8787C9.32016 17.2692 9.32016 17.9023 9.71069 18.2929Z" fill="white"/>
        </svg>
      </div>
      <div className="list_episode" ref={scrollContainerRef} onMouseDown={handleMouseDown}>
        {data.animes.map((anime, index) => {
          const serverAnime = findAnimeOnServer(anime.id);
          const displayAnime = serverAnime || anime;

          return (
            <div
              key={displayAnime.id}
              className={`catalog_item ${expandedItem === index ? 'expanded' : ''}`}
              onClick={() => handleItemClick(index)}
            >
              {/* Условный рендеринг .item_hover */}
              <div className="item_hover">
                <div className="item_hover_name">{displayAnime.russian || displayAnime.title}</div>
                <div className="item_hover_genres">
                  {getGenres(displayAnime)}
                </div>
                <div className="item_hover_description">
                  {cleanDescription(displayAnime.description)}
                </div>
                <div className="item_hover_butt_detail">
                  <a href={`/play/${displayAnime.id}`}>
                    <div className="item_butt_watch">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10px" height="10px" viewBox="-0.5 0 7 7" version="1.1">
                          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                              <g id="Dribbble-Light-Preview" transform="translate(-347.000000, -3766.000000)" fill="#000000">
                              <g id="icons" transform="translate(56.000000, 160.000000)">
                                  <path d="M296.494737,3608.57322 L292.500752,3606.14219 C291.83208,3605.73542 291,3606.25002 291,3607.06891 L291,3611.93095 C291,3612.7509 291.83208,3613.26444 292.500752,3612.85767 L296.494737,3610.42771 C297.168421,3610.01774 297.168421,3608.98319 296.494737,3608.57322" id="play-[#1003]"></path>
                              </g>
                              </g>
                          </g>
                      </svg>
                      <span>{serverAnime ? 'Смотреть в 4К' : 'Смотреть'}</span>
                    </div>
                  </a>
                  <a href={`/anime/${displayAnime.id}`}>
                    <div className="item_butt_detailed">
                      <span>Подробнее</span>
                    </div>
                  </a>
                </div>
              </div>
              <div className={`item ${expandedItem === index ? 'expanded' : ''}`}>
                <div className="container_catalog_item_tag">
                  {serverAnime && (
                    <div className='item_tag' style={{ backgroundColor: '#ff3f3f', color: 'white', fontWeight: '500' }}>4K</div>
                  )}
                  <div
                    className={`item_tag ${
                      displayAnime.score >= 8 ? 'hard_score' : displayAnime.score <= 7 ? 'low_score' : 'high_score'
                    }`}
                  >
                    {renderScoreWithSVG(displayAnime.score)}
                  </div>
                  {/* Добавляем элемент 4K, если данные берутся с сервера */}
                </div>
                {!serverAnime && <div className='poster_overlay'></div>}
                {displayAnime.poster ? (
                  <img
                    src={serverAnime ? `http://localhost:5000${displayAnime.poster}` : displayAnime.poster.originalUrl}
                    style={{ backgroundImage: `url('https://aniplay.fun/icons/fon_item.jpg')` }}
                  />
                ) : (
                  <div className="no-poster">Постер не доступен</div>
                )}
                {!serverAnime && (
                  <div className="item_name">{displayAnime.russian || displayAnime.title}</div>
                )}
                <div className="overlay"></div>
              </div>
              {/* Если данные берутся не с сервера, отображаем название аниме */}
            </div>
          );
        })}
      </div>
    </div>
  );
}
