import React from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import Auth from '../components/Auth';
import { Helmet } from "react-helmet";

function login() {
  return (
    <div>
      <Helmet>
        <title>Авторизация</title>
      </Helmet>
      <Header />
      <Auth />
      <Footer />
    </div>
  )
}

export default login
