import React from 'react';
import Welcome from "./page/welcome";
import Anime from './page/anime';
import AllOngoing from './page/all=1';
import AllAnons from './page/all=2';
import AllMovie from './page/all=3';
import { Route, Routes, Navigate } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import client from './components/apolloClient';
import Play from './page/play';
import Profile from './page/profile'
import Auth from './page/auth'
import Settings from './page/settings';
import Agreement from './page/agreement';
import PrivacyPolicy from './page/privacy';
import PublicOffer from './page/public';
import Genre from './page/genre';
import Player from './page/player';

function App() {
  return (
    <ApolloProvider client={client}> 
      <div className="aniplay.fun">
        <Routes>
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/profile" element={<Profile />}/>
          <Route path="/settings" element={<Settings />}/>
          <Route path="/auth" element={<Auth />}/>
          <Route path="/anime/:id" element={<Anime />} />
          <Route path="/all=1" element={<AllOngoing />} />
          <Route path="/all=2" element={<AllAnons />} />
          <Route path="/all=3" element={<AllMovie />} />
          <Route path='/play/:id' element={<Player />} />
          <Route path='/agreement' element={<Agreement />}/>
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/public" element={<PublicOffer />} />
          <Route path='/genre/:id' element={<Genre />}/>
          <Route path="*" element={<Navigate to="/welcome" replace />} />
        </Routes>
      </div>
    </ApolloProvider>
  );
}

export default App;
