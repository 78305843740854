import React, { Component } from 'react'
import TestPlayer from '../components/TestPlayer'
import Header from '../components/Header'

export default class player extends Component {
  render() {
    return (
    <div>
        <Header />
        <TestPlayer />
    </div>
    )
  }
}
