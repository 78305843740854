import React from 'react';
import Header from '../components/Header';
import Banner from '../components/ProfileUserInfo';

function Profile() {
  return (
    <div>
      <Header />
      <Banner />
    </div>
  );
}

export default Profile;
