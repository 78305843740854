import React, { useState, useRef, useEffect } from 'react';

export default function BigBanner() {
  // Массив с информацией о видео
  const videoData = [
    {
      src: '../storage/22319.webm',
      title: 'Токийский гуль',
      title_en: 'Tokyo Ghoul',
      ids: 22319,
      title_poster: '../media/posters_logo/logo(22319).png',
      width_title_poster: '350px',
      genres: 'Сэйнэн, Экшен, Фэнтези, Ужасы, Триллер',
      type: 'TV сериал',
      description: 'В Токио происходит серия жестоких убийств. На местах преступлений практически нет улик, однако все уверены, что виновниками являются гули, пожирающие людей, хотя никто толком не знает, кто они и как выглядят. события мало волнуют Кэна Канэки , но вместе со своим лучшим другом Хидэёши Нагачикой он считает, что гулей до сих пор не обнаружили только потому, что они ловко маскируются под своих жертв... Кэн любит книги и частенько читает их в кафе. Однажды его привлекла одна девушка, читающая книгу в этом самом кафе, причём, того же автора, что и книга в руках главного героя. Однако почему такой редкий шанс начать взаимоотношения с прекрасной девушкой кончается провалом? И почему она оказалась гулем? Что же может произойти со студентом первого курса филологического факультета? Шок, потеря сознания, больница, операция, новый желудок. ] знакомство изменило его жизнь: теперь он не человек, но и не гуль. Какое решение он примет и что его ждёт? Кэн Канэки, кем же ты станешь: охотником или добычей?',
      image: '../media/image/22319.jpg',
    },
    {
      src: '../storage/11061.webm',
      title: 'Охотник х Охотник',
      title_en: 'Hunter x Hunter',
      ids: 11061,
      title_poster: '../media/posters_logo/logo(11061).png',
      width_title_poster: '200px',
      genres: 'Сёнен, Экшен, Приключения, Фэнтези',
      type: 'TV сериал',
      description: 'Охотник — это тот, кто путешествует по миру, выполняя различные опасные миссии: от поимки преступников до поиска сокровищ в неизведанных землях. Главный герой — мальчик по имени Гон . Его отец Джин был охотником, но исчез много лет назад. Гон считает, что если пойдёт по стопам отца и станет охотником, то рано или поздно вновь встретится с ним. Мальчик надеется, что, повстречав отца, наконец сможет задать ему один-единственный вопрос: почему он предпочёл жизнь охотника своему маленькому сынишке. Когда ему исполняется двенадцать, Гон, как в своё время и его отец, покидает родной остров с целью сдать официальный экзамен на охотника. Но экзамен очень сложен, и каждый год множество людей погибают, пытаясь получить это звание. Во время своего путешествия Гон знакомится с Курапикой, Леорио и Киллуа, вместе с которыми ступает на тернистый путь охотника. Ремейк аниме-сериала «Охотник х Охотник» 1999 года.',
      image: '../media/image/11061.jpg',
    },
    {
      src: '../storage/57334.webm',
      title: 'Дандадан',
      title_en: 'Dandadan',
      ids: 57334,
      title_poster: '../media/posters_logo/logo(57334).png',
      width_title_poster: '220px',
      genres: 'Сёнен, Экшен, Комедия, Сверхъестественное',
      type: 'TV сериал',
      description: 'Хотите узнать, что будет, если взять внучку экстрасенса, неверящую в НЛО, и фаната уфологии, который и думать не желает о призраках? А будет самое настоящее испытание на храбрость или, скорее, испытание верой, во время которого каждый из них захочет показать другому ту сторону реальности, о которой второй даже не задумывался. А вот если эти самые призраки и пришельцы начнут активно на них нападать, то из такого сочетания несочетаемого вполне может получиться достойная команда! Это история о популярной красавице Момо Аясэ и типичном заучке Кэне Такакуре — старшеклассниках, которые вынуждены бороться с чёрт пойми кем во имя их собственного целомудрия и спокойствия на планете Земля!',
      image: '../media/image/57334.jpg',
    },
    {
      src: '../storage/11757.webm',
      title: 'Мастера Меча Онлайн',
      title_en: 'Sword Art Online',
      ids: 11757,
      title_poster: '../media/posters_logo/logo(11757).png',
      width_title_poster: '350px',
      genres: 'Экшен, Приключения, Фэнтези, Романтика',
      type: 'TV сериал',
      description: 'И вот вышла многопользовательская игра нового поколения — игра, где смерть реальна и бегство невозможно. Единственный выход — дойти до конца. А называется игра «Sword Art Online». Не зная этой правды, десять тысяч игроков, используя нейрошлемы компании «Агис», зарегистрировались одновременно и тем самым положили начало смертельной битве, длящейся уже два долгих года. Главный герой Кирито принимает правила игры и не собирается сдаваться. В этом игровом мире, в гигантской парящей крепости под названием Айнкрад, он стал игроком-одиночкой. Чтобы выйти из игры, ему придётся пройти все 100 этажей этого огромного сооружения. Однако, не в силах противостоять настойчивости мастера рапиры Асуны , он начинает работать с ней в паре. Возможно, эта встреча предопределит судьбу всей игры...',
      image: '../media/image/11757.jpg',
    },
    {
      src: '../storage/57181.webm',
      title: 'Голубая шкатулка',
      title_en: 'Ao no Hako',
      ids: 57181,
      title_poster: '../media/posters_logo/logo(57181).png',
      width_title_poster: '380px',
      genres: 'Сёнен, Романтика, Спорт',
      type: 'TV сериал',
      description: '«Каждое утро... есть один человек, которого я всегда очень хочу увидеть» Тайки Иномата учится в школе со спортивным уклоном «Эймэй» и состоит в команде по бадминтону. Каждое утро Тайки стремится первым попасть в спортзал, который всегда открыт для желающих лишний раз потренироваться, но, как бы он ни старался, там всегда есть, кому поприветствовать его. Когда-то Тинацу Кано, раз за разом отрабатывающая баскетбольные броски по утрам, была для Тайки не более чем примером для подражания, но сейчас она главная мотивация для раннего подъёма и дополнительных тренировок. И, хоть Тинацу и кажется недосягаемой звездой, Тайки намерен сделать всё, чтобы однажды дотянуться до неё. Однако... кто мог ожидать, что расстояние между ними сократится в самом прямом смысле?!',
      image: '../media/image/57181.jpg',
    },
    {
      src: '../storage/55701.webm',
      title: 'Клинок, рассекающий демонов: Тренировка столпов',
      title_en: 'Kimetsu no Yaiba: Hashira Geiko-hen',
      ids: 55701,
      title_poster: '../media/posters_logo/logo(55701).png',
      width_title_poster: '180px',
      genres: 'Сёнен, Экшен, Сверхъестественное',
      type: 'TV сериал',
      description: 'Инцидент в Деревне кузнецов завершился, и его последствия могут оказаться судьбоносными для Японии. Собравшись на срочное совещание, столпы, обсудив текущую ситуацию, приходят к заключению, что нынешние охотники на демонов слишком слабы, чтобы оказывать достойное сопротивление Молодым лунам. Такое положение дел чревато большими человеческими потерями и может поставить под угрозу существование самой организации. Каменный столп Гёмэй Химэдзима предлагает устроить рядовым охотникам тренировку. Разделившись на группы, бойцы побывают у каждого столпа по очереди и усовершенствуют свои боевые навыки. Тренировки обещают быть сверхинтенсивными и экстра беспощадными. Но такова цена выживания в эту непростую эпоху.',
      image: '../media/image/55701.jpg',
    },
    {
      src: '../storage/57058.webm',
      title: 'Я парировал всё время, чтобы стать сильнейшим авантюристом',
      title_en: 'Ore wa Subete wo "Parry" suru: Gyaku Kanchigai no Sekai Saikyou wa Boukensha ni Naritai',
      ids: 57058,
      title_poster: '../media/posters_logo/logo(57058).png',
      width_title_poster: '425px',
      genres: 'Экшен Приключения Фэнтези',
      type: 'TV сериал',
      description: 'Творятся какие-то тёмные и опасные для страны дела, понимает правящая верхушка королевства Клейз. Совершено покушение на принцессу, стали появляться сверхмощные монстры, явно созданные людскими руками, кто-то подталкивает страну к развязыванию войны. Простолюдин Ноор не имеет об этом всём ни малейшего понятия. Оставшись в детстве сиротой, он, как завещала ему перед смертью мать, всю жизнь стремился к исполнению своей мечты. А мечтал он о том, чтобы стать авантюристом. К сожалению, он в этом не преуспел. Как оказалось, у него нет ни одного приличного навыка. Пройдя через несколько тренировочных центров и так ничего и не добившись, Ноор не отчаялся, а продолжил заниматься в одиночку, параллельно нанимаясь на всякие подсобные работы. Десять лет ежедневных тренировок привели к тому, что Ноор теперь может парировать одновременную атаку сразу тысячи мечей. Только ведь этого мало, чтобы стать авантюристом, так? Но ничего страшного, Ноор не против проверенного способа добиться цели — он готов и дальше упорно трудиться над собой!',
      image: '../media/image/57058.jpg',
    },
    {
      src: '../storage/35507.webm',
      title: 'Добро пожаловать в класс превосходства',
      title_en: 'Youkoso Jitsuryoku Shijou Shugi no Kyoushitsu e',
      ids: 35507,
      title_poster: '../media/posters_logo/logo(35507).png',
      width_title_poster: '320px',
      genres: 'Драма, Триллер',
      type: 'TV сериал',
      description: 'Старшая школа Кодо Икусэй — престижное учебное заведение, окончив которое, выпускники гарантированно поступят в университет или найдут отличную работу. Учащимся позволено ходить с любыми прическами и приносить любые личные вещи. Кодо Икусэй — школа, похожая на рай, но правда состоит в том, что такой подход применим только к ученикам с высокой успеваемостью. Первогодка Киётака Аянокоджи учится в классе D, куда руководство школы «сливает» студентов, набравших низкие показатели на вступительных экзаменах. Шаг за шагом он вливается в школьную жизнь и заводит новые знакомства, благодаря чему его положение постепенно начинает меняться.',
      image: '../media/image/35507.jpg',
    },
    {
      src: '../storage/55791.webm',
      title: 'Ребёнок идола 2',
      title_en: '"Oshi no Ko" 2nd Season',
      ids: 55791,
      title_poster: '../media/posters_logo/logo(55791).png',
      width_title_poster: '330px',
      genres: 'Сэйнэн Драма Сверхъестественное',
      type: 'TV сериал',
      description: 'Наблюдая за выступлениями актёров на экранах телевизоров может показаться, что быть знаменитостью легко и весело. Однако за кулисами между актёрами нередко разгораются настоящие интриги, серьёзные противостояния и ожесточённые состязания. Во втором сезоне нашим героям предстоит сыграть в театральной постановке манги "Токийский клинок". Вместе с ними мы узнаем о секретах театра Лалалай, о том, что главная битва может идти не между персонажами, а между самими актёрами, а также о тайнах, мастерски сокрытых долгими годами притворства и лжи.',
      image: '../media/image/55791.jpg',
    },
    {
      src: '../storage/54492.webm',
      title: 'Монолог фармацевта',
      title_en: 'Kusuriya no Hitorigoto',
      ids: 54492,
      title_poster: '../media/posters_logo/logo(54492).png',
      width_title_poster: '350px',
      genres: 'Драма, Тайна',
      type: 'TV сериал',
      description: 'Уже полгода прошло с того момента, как 17-летнюю Маомао похитили и заставили трудиться в императорском дворце обычной служанкой. Работа тяжёлая, но девушка решила не сдаваться, не унывать и честно вкалывать, пока её не отпустят на покой. Планы изменились, когда до Маомао дошли вести о том, что детей императора одолел серьёзный недуг. Девушка решила тайком попробовать разобраться и помочь, рассчитывая на свой опыт в фармацевтике, которой она занималась раньше, когда проживала в Квартале красных фонарей. Несмотря на то, что Маомао не хотела привлекать к себе внимания, её вмешательство и талант не остались незамеченными. Вскоре Маомао оказалась вхожа во внутренние покои и вступила в круг приближённых императора. Благодаря своим знаниям и эксцентричному характеру Маомао произведёт фурор во дворце!',
      image: '../media/image/54492.jpg',
    },
    {
      src: '../storage/22199.webm',
      title: 'Убийца Акамэ!',
      title_en: 'Akame ga Kill!',
      ids: 22199,
      title_poster: '../media/posters_logo/logo(22199).png',
      width_title_poster: '300px',
      genres: 'Сёнен, Экшен, Фэнтези',
      type: 'TV сериал',
      description: 'Прославиться в столице — это мечта любого деревенского парня. Главный герой, Тацуми, не стал исключением. Решив, что хочет спасти свою деревню, он отправляется в столицу вместе со своими друзьями, ведь именно там легче всего заработать деньги — вернее, он так считал. В первый же день в столице его обводят вокруг пальца, оставив без денег. Казалось бы, теперь всё потеряно... Ни денег, ни работы, абсолютно ничего. Однако он встречает добрую аристократку Арию , которая соглашается приютить его. В ночь того же дня в дом новой знакомой Тацуми вторгаются люди (в том числе девушка, которая ранее обокрала его), а точнее революционеры, известные как «Ночной рейд». Они утверждают, что Ария на самом деле маньяк в юбке, а не та, за кого себя выдаёт. Кому поверит Тацуми? Революционерам или же девушке, которая помогла ему?',
      image: '../media/image/22199.jpg',
    },
  ];

  const [isMuted, setIsMuted] = useState(true);


  const [isImageVisible, setIsImageVisible] = useState(true);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [hasShownTextBannerImage, setHasShownTextBannerImage] = useState(false);

  // Считываем начальный индекс из localStorage или устанавливаем 0 по умолчанию
  const savedIndex = localStorage.getItem("currentBannerIndex");
  const initialIndex = savedIndex ? parseInt(savedIndex, 10) : 0;
  const [currentVideoIndex, setCurrentVideoIndex] = useState(initialIndex);

  // Считываем время до следующей смены из localStorage
  const savedTime = localStorage.getItem("timeUntilNextChange");
  const initialTime = savedTime ? parseInt(savedTime, 10) : 3600;  // Время до следующей смены в секундах

  const [currentBannerIndex, setCurrentBannerIndex] = useState(initialIndex);
  const [timeUntilNextChange, setTimeUntilNextChange] = useState(initialTime);

  const observerRef = useRef(null);
  const textBannerRef = useRef(null);
  const videoRef = useRef(null);

  // Функция для обновления баннера
  const updateBanner = () => {
    const nextIndex = (currentBannerIndex + 1) % videoData.length;
    setCurrentBannerIndex(nextIndex);
    localStorage.setItem("currentBannerIndex", nextIndex); // Сохраняем новый индекс баннера
    localStorage.setItem("lastBannerChange", Date.now()); // Сохраняем время последней смены
  };

  // Функция для обновления времени до следующей смены
  const updateTime = () => {
    setTimeUntilNextChange((prev) => {
      if (prev <= 1) {
        updateBanner(); // Обновляем баннер
        return 3600; // Сбрасываем таймер до 1 часа (3600 секунд)
      }
      return prev - 1; // Уменьшаем таймер на 1 секунду
    });
  };

  // Считываем время последней смены баннера при монтировании компонента
  useEffect(() => {
    const lastChange = localStorage.getItem("lastBannerChange");
    const timeSinceLastChange = lastChange ? (Date.now() - lastChange) / 1000 : 0;
    const adjustedTime = Math.max(0, initialTime - timeSinceLastChange); // Корректируем оставшееся время
  
    setTimeUntilNextChange(adjustedTime); // Устанавливаем скорректированное время
  
    const intervalId = setInterval(updateTime, 1000); // Обновляем каждую секунду
  
    return () => clearInterval(intervalId); // Очистить интервал при размонтировании компонента
  }, []); // Эффект с пустым массивом зависимостей, сработает только при монтировании компонента

  // Сохраняем время до следующей смены в localStorage каждый раз, когда оно изменяется
  useEffect(() => {
    localStorage.setItem("timeUntilNextChange", timeUntilNextChange);
  }, [timeUntilNextChange]);

  // Функция для переключения звука
  const handleSoundToggle = () => {
    if (volume === 0) {
      setVolume(50); // Устанавливаем громкость на 50 или другое значение, если звук включается
    } else {
      setIsMuted(!isMuted); // Переключаем состояние mute
    }
  };

  // Обработка окончания видео
  const handleVideoEnd = () => {
    setIsTransitioning(true);
    setTimeout(() => {
      setIsImageVisible(true);
      setIsTransitioning(false);
    }, 500);
  };

  // Инициализация баннера при монтировании
  useEffect(() => {
    setIsImageVisible(true); // Устанавливаем начальное состояние видимости изображения
  }, []);

  // Переключение баннера на следующий
  const nextBanner = () => {
    const nextIndex = (currentBannerIndex + 1) % videoData.length;
    setCurrentBannerIndex(nextIndex);
    localStorage.setItem('currentBannerIndex', nextIndex); // Сохраняем новый индекс
  };

  // Сохранение текущего индекса баннера в localStorage при его изменении
  useEffect(() => {
    localStorage.setItem('currentBannerIndex', currentBannerIndex);
  }, [currentBannerIndex]);

  // Обновление видео и воспроизведение на старте
  useEffect(() => {
    const video = document.getElementById("videoBanner");
    if (video) {
      video.load();
      if (timeUntilNextChange <= 1) {
        video.play().catch((error) => console.error("Ошибка воспроизведения видео:", error));
      }
    }
  }, [currentBannerIndex]);

  // Логика для анимации текстового баннера
  useEffect(() => {
    if (isImageVisible && !hasShownTextBannerImage) {
      animateTextBanner(true);
      setHasShownTextBannerImage(); // Фиксируем факт показа баннера
    }
  }, [isImageVisible]); // Убираем зависимость от hasShownTextBannerImage // Эффект срабатывает только когда изображение видно и не показывалось ранее
  

  // Функция для анимации текстового баннера
  const animateTextBanner = () => {
    const textBanner = textBannerRef.current;
    if (textBanner) {
      textBanner.style.transform = "scale(1.5)";
      setTimeout(() => {
        textBanner.style.transition = "transform 0.5s ease";
        textBanner.style.transform = "scale(1)";
      }, 50);
    }
  };

  // Переключение видимости изображения баннера
  useEffect(() => {
    const videoTransitionTimer = setTimeout(() => {
      setIsTransitioning(true);
      setTimeout(() => {
        setIsImageVisible(false);
        setIsTransitioning(false);
      }, 500);
    }, 5000);
  
    return () => clearTimeout(videoTransitionTimer);
  }, [currentVideoIndex]);


  // Используем IntersectionObserver для автоматического воспроизведения видео, когда оно на экране
  useEffect(() => {
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        const video = videoRef.current;
        if (video) {
          if (entry.isIntersecting && !isImageVisible) {
            if (video.paused) {
              const playPromise = video.play();
              if (playPromise !== undefined) {
                playPromise.catch((error) => {
                  console.error('Ошибка воспроизведения видео:', error);
                });
              }
            }
          } else if (!video.paused) {
            video.pause();
          }
        }
      });
    };

    observerRef.current = new IntersectionObserver(handleIntersection, {
      threshold: 0.5,
    });

    if (videoRef.current) {
      observerRef.current.observe(videoRef.current);
    }

    return () => {
      if (observerRef.current && videoRef.current) {
        observerRef.current.unobserve(videoRef.current);
      }
    };
  }, [isImageVisible]);


  const [volume, setVolume] = useState(100); // Начальный уровень громкости




  return (
    <div className="BigBanner">
      {isImageVisible ? (
        <>
          <img
            className={`image-fade ${isTransitioning ? 'image-fade-out' : 'image-fade-in'}`}
            src={videoData[currentVideoIndex].image}
            type='mp4'
          />
          <div className='big_banner_image_gradient'></div>
        </>
      ) : (
        <video
          ref={videoRef}
          className={`video-fade ${isTransitioning ? 'video-fade-out' : 'video-fade-in'}`}
          src={videoData[currentVideoIndex].src}
          muted={isMuted}
          onEnded={() => {
            handleVideoEnd();
            setHasShownTextBannerImage(true);
          }}
          onLoadedData={() => {
            if (observerRef.current) {
              const entries = observerRef.current.takeRecords();
              entries.forEach((entry) => {
                if (entry.isIntersecting) {
                  videoRef.current.play().catch((error) => console.error('Ошибка воспроизведения видео:', error));
                }
              });
            }
          }}
        />
      )}

      {/* Отображение text_Banner_image только при первом показе картинки */}
      {isImageVisible && !hasShownTextBannerImage ? (
        <div className={`text_Banner_image`}>
          <div ref={textBannerRef} className='title'>
            <img style={{ width: videoData[currentVideoIndex].width_title_poster }} src={videoData[currentVideoIndex].title_poster} alt="Title" />
            <div className='description'>{videoData[currentVideoIndex].description}</div>
          </div>
        </div>
      ) : (
        <div className={`text_Banner text-fade ${isTransitioning ? 'text-fade-out' : 'text-fade-in'}`}>
          <div className='title'>
            <img style={{ width: videoData[currentVideoIndex].width_title_poster }} src={videoData[currentVideoIndex].title_poster} alt="Title" />
          </div>
          <div className='info'>
            <div 
              className='description' 
              style={{ display: isImageVisible ? '-webkit-box' : 'none' }}
            >
              {videoData[currentVideoIndex].description}
            </div>
          </div>
          <div className='butt_detail'>
            <div>
              <a href={`/play/${videoData[currentVideoIndex].ids}`}>
                <div className='butt_wath'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="-0.5 0 8 8" version="1.1">
                      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <g id="Dribbble-Light-Preview" transform="translate(-427.000000, -3765.000000)" fill="#000000">
                              <g id="icons" transform="translate(56.000000, 160.000000)">
                                  <polygon id="play-[#1001]" points="371 3605 371 3613 378 3609"></polygon>
                              </g>
                          </g>
                      </g>
                  </svg>
                  <span>Смотреть</span>
                </div>
              </a>
              <a href={`/anime/${videoData[currentVideoIndex].ids}`}>
                <div className='butt_details'>
                  <span>Подробнее</span>
                </div>
              </a>
            </div>
            {!isImageVisible && (
            <div className="block_butt_sound">
              <div
                className="butt_sound"
                onClick={handleSoundToggle} // Эта функция переключает состояние isMuted
              >
                {isMuted ? (
                  // Иконка для выключенного звука (если звук выключен или громкость равна 0)
                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 512 512">
                    <g id="icon" fill="#ffff" transform="translate(42.666667, 59.581722)">
                      <path d="M47.0849493,-1.42108547e-14 L298.668,251.583611 L304.101001,257.015597 L304.101,257.016 L353.573532,306.488791 C353.573732,306.488458 353.573933,306.488124 353.574133,306.48779 L384.435257,337.348961 L384.434,337.349 L409.751616,362.666662 L379.581717,392.836561 L191.749,205.003 L191.749973,369.105851 L81.0208,283.647505 L7.10542736e-15,283.647505 L7.10542736e-15,112.980838 L80.8957867,112.980838 L91.433,104.688 L16.9150553,30.169894 L47.0849493,-1.42108547e-14 Z M361.298133,28.0146513 C429.037729,103.653701 443.797162,209.394226 405.578884,298.151284 L372.628394,265.201173 C396.498256,194.197542 381.626623,113.228555 328.013013,54.642278 L361.298133,28.0146513 Z M276.912853,95.5237713 C305.539387,127.448193 318.4688,168.293162 315.701304,208.275874 L266.464558,159.040303 C261.641821,146.125608 254.316511,133.919279 244.488548,123.156461 L243.588693,122.182545 L276.912853,95.5237713 Z M191.749973,25.7516113 L191.749,84.3256113 L158.969,51.5456113 L191.749973,25.7516113 Z"></path>
                    </g>
                  </svg>
                ) : (
                  // Иконка для включенного звука (если громкость больше 0 и звук не выключен)
                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 512 512">
                    <g id="icon" fill="#ffff" transform="translate(42.666667, 85.333333)">
                      <path d="M361.299413,341.610667 L328.014293,314.98176 C402.206933,233.906133 402.206933,109.96608 328.013013,28.8906667 L361.298133,2.26304 C447.910187,98.97536 447.908907,244.898347 361.299413,341.610667 Z M276.912853,69.77216 L243.588693,96.4309333 C283.38432,138.998613 283.38304,204.87488 243.589973,247.44256 L276.914133,274.101333 C329.118507,215.880107 329.118507,127.992107 276.912853,69.77216 Z M191.749973,1.42108547e-14 L80.8957867,87.2292267 L7.10542736e-15,87.2292267 L7.10542736e-15,257.895893 L81.0208,257.895893 L191.749973,343.35424 L191.749973,1.42108547e-14 L191.749973,1.42108547e-14 Z"></path>
                    </g>
                  </svg>
                )}
              </div>
            </div>
          )}

          </div>
        </div>
      )}
    </div>
  );
}