import React, { Component } from 'react';
import Settings from '../components/Settings';
import Header from '../components/Header';

export default class settings extends Component {
  render() {
    return (
      <div className='settings'>
        <Header />
        <Settings />
      </div>
    )
  }
}
