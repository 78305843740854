import { ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import debounce from 'lodash.debounce';
import { Observable } from '@apollo/client';

// Обработка ошибок с повторным запросом при 429
const errorLink = onError(({ networkError, operation, forward }) => {
  if (networkError && networkError.statusCode === 429) {
    console.warn("429 Too Many Requests. Повторная попытка через 3 секунды...");
    
    return new Observable(observer => {
      setTimeout(() => {
        forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        });
      }, 3000);
    });
  }
});

// Настройка основного HttpLink
const httpLink = new HttpLink({
  uri: 'https://shikimori.one/api/graphql',
});

// Дебаунс-функция для ограничения количества запросов
const debouncedQuery = debounce((client, options) => client.query(options), 500);

// Создаем Apollo Client с линком для ошибок
const client = new ApolloClient({
  link: from([errorLink, httpLink]), // Подключаем errorLink для обработки ошибок 429
  cache: new InMemoryCache(),
});

// Функция с дебаунсом для выполнения запросов
export const executeQuery = (options) => debouncedQuery(client, options);

export default client;
