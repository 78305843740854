import React, { useEffect, useState, useRef } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { useLazyQuery, gql, useQuery } from '@apollo/client';
import DOMPurify from 'dompurify';

const SEARCH_ANIME_QUERY = gql`
  query searchAnime($search: String, $status: AnimeStatusString, $kind: AnimeKindString, $limit: Int, $order: OrderEnum) {
    animes(search: $search, status: $status, kind: $kind, limit: $limit, order: $order) {
      id
      russian
      score
      kind
      episodesAired
      poster {
        originalUrl
      }
      genres {
        russian
        kind
      }
      airedOn {
        year
      }
    }
  }
`;


export default function Header() {
  const location = useLocation();
  const [activePath, setActivePath] = useState('/welcome');
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const navigate = useNavigate();
  const [isClosing, setIsClosing] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isSearchResult, setIsSearchResult] = useState(false);

  const [searchAnime, { data, loading, error }] = useLazyQuery(SEARCH_ANIME_QUERY);
  const { data: upcomingReleasesData } = useQuery(SEARCH_ANIME_QUERY, {
    variables: { status: 'released', limit: 25 },
    fetchPolicy: 'cache-and-network',
  });
  const menuRef = useRef(null);
  const profileRef = useRef(null);
  const isAuthenticated = !!localStorage.getItem('token');

  const [isFilterVisible, setIsFilterVisible] = useState(true);
  const headerSearchBackgroundRef = useRef(null);

  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [selectedSorting, setSelectedSorting] = useState('');
  const [selectedGrade, setSelectedGrade] = useState('');

  const [avatar, setAvatar] = useState(null); // Store avatar state
  const [profileData, setProfileData] = useState(null);



  useEffect(() => {
    setActivePath(location.pathname);
  }, [location]);

  // Set initial search results to upcoming releases
  useEffect(() => {
    if (isSearchVisible && upcomingReleasesData && upcomingReleasesData.animes) {
      setSearchResults(upcomingReleasesData.animes);
    }
  }, [upcomingReleasesData, isSearchVisible]);

  useEffect(() => {
    if (data && !loading) {
      setSearchResults(data.animes);
    } else if (error) {
      console.error('Error fetching data:', error);
    }
  }, [data, loading, error]);

  // Обновляем handleSearchClick для установки начальных результатов только при открытии поиска
  const handleSearchClick = () => {
    setIsSearchVisible(true);
    setIsClosing(false);
    // Устанавливаем начальные результаты при открытии поиска
    if (upcomingReleasesData && upcomingReleasesData.animes) {
      setSearchResults(upcomingReleasesData.animes);
    }
  };

  // Закрываем поиск и очищаем результаты
  const handleCloseSearchClick = () => {
    setIsSearchVisible(false);
    setSearchResults([]); // Очищаем результаты при закрытии поиска
  };

  const handleInputChange = (e) => {
    if (!isSearchVisible) return; // Прерываем выполнение, если блок поиска не открыт
  
    const inputValue = e.target.value;
  
    // Очищаем ввод от скриптов и тегов
    const sanitizedQuery = DOMPurify.sanitize(inputValue);
    setSearchQuery(sanitizedQuery);
  
    if (sanitizedQuery.length > 3) {
      searchAnime({ variables: { search: sanitizedQuery, status: selectedStatus || '', limit: 25 } });
    } else {
      setSearchResults(upcomingReleasesData?.animes || []);
    }
  }

  const selectedStatusMap = {
    'Вышедшее': 'released',
    'Сейчас выходит': 'ongoing',
    'Анонсировано': 'anons',
  };

  const kindTranslations = {
    tv: 'TV Сериал',
    movie: 'Фильм',
    ova: 'OVA',
    ona: 'ONA',
    special: 'Спецвыпуск',
    tv_special: 'TV Спецвыпуск',
    music: 'Музыка',
    cm: 'Реклама',
  };

  const statusMap = {
    ongoing: 'ongoing',
    released: 'released',
    anons: 'anons',
  };

  const handleFilterChange = () => {
    if (!isSearchVisible) return;
  
    const status = selectedStatusMap[selectedStatus] || '';
    const kind = Object.keys(kindTranslations).find(key => kindTranslations[key] === selectedType) || '';
  
    const queryVariables = {
      search: searchQuery.trim() || undefined,
      status: status || undefined,
      kind: kind || undefined,
      limit: 25,
    };
  
    searchAnime({
      variables: queryVariables,
    });
  };
  

  // Call handleFilterChange whenever selectedStatus changes
  useEffect(() => {
    handleFilterChange();
  }, [selectedStatus]); // Trigger on selectedStatus change only

  const handleStatusChange = (status) => {
    setSelectedStatus((prevStatus) => (prevStatus === status ? '' : status)); // Toggle the selected status
    console.log('Selected status changed to:', status);
  };

  const handleTypeChange = (type) => {
    setSelectedType((prevType) => (prevType === type ? '' : type)); // Переключение выбранного типа
  };

  const sortingMap = {
    'По рейтингу': '', // No specific order
    'По популярности': 'popularity',
    'По дате выхода': 'aired_on',
  };

  const handleSortingChange = (sorting) => {
    setSelectedSorting((prevSelected) => (prevSelected === sorting ? '' : sorting));
  
    const orderValue = sortingMap[sorting] || undefined;
    
    // Trigger search with the new sorting order
    searchAnime({
      variables: {
        search: searchQuery.trim() || undefined,
        status: selectedStatusMap[selectedStatus] || undefined,
        kind: Object.keys(kindTranslations).find(key => kindTranslations[key] === selectedType) || undefined,
        limit: 25,
        order: orderValue, // Pass the selected order value here
      },
    });
  };

  useEffect(() => {
    handleFilterChange();
  }, [selectedStatus, selectedType]); // Теперь реагирует на изменения статуса и типа


  useEffect(() => {
    const handleEscKey = (e) => {
      if (e.key === 'Escape') {
        handleCloseSearchClick();
        setIsMenuVisible(false);
      }
    };

    window.addEventListener('keydown', handleEscKey);

    return () => {
      window.removeEventListener('keydown', handleEscKey);
    };
  }, []);

  // Обработчик кликов вне меню поиска и меню пользователя
  useEffect(() => {
    const handleClickOutside = (e) => {
      // Проверка для контейнера поиска
      const isInsideSearchContainer = headerSearchBackgroundRef.current && headerSearchBackgroundRef.current.contains(e.target);
      
      // Проверка для меню пользователя
      const isProfile = profileRef.current && profileRef.current.contains(e.target);
      const isMenu = menuRef.current && menuRef.current.contains(e.target);
  
      // Закрываем меню пользователя, если клик был вне его
      if (isMenuVisible && !isProfile && !isMenu) {
        setIsMenuVisible(false);  // Выполняем действие: закрытие меню
      }
  
      // Закрываем поиск, если клик был на header_search_background_all
      if (isSearchVisible && headerSearchBackgroundRef.current && headerSearchBackgroundRef.current === e.target) {
        handleCloseSearchClick(); // Закрыть поиск
      }
    };
  
    // Обработчик кликов на сам контейнер поиска
    const handleSearchBackgroundClick = (e) => {
      // Проверяем, если клик не произошел на дочерних элементах
      if (e.target === headerSearchBackgroundRef.current) {
        handleCloseSearchClick(); // Закрыть поиск, если клик на header_search_background_all
      }
    };
  
    // Добавляем обработчики событий
    window.addEventListener('click', handleClickOutside);
    if (headerSearchBackgroundRef.current) {
      headerSearchBackgroundRef.current.addEventListener('click', handleSearchBackgroundClick);
    }
  
    // Очистка обработчиков событий при размонтировании компонента
    return () => {
      window.removeEventListener('click', handleClickOutside);
      if (headerSearchBackgroundRef.current) {
        headerSearchBackgroundRef.current.removeEventListener('click', handleSearchBackgroundClick);
      }
    };
  }, [isSearchVisible, isMenuVisible, handleCloseSearchClick, headerSearchBackgroundRef, profileRef, menuRef]);
  




  const toggleFilter = () => {
    setIsFilterVisible((prev) => !prev);
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch('/api/your-endpoint');
      const data = await response.json();
      setSearchResults(data);
    };
  
    fetchData();
  }, []);

  useEffect(() => {
    if (isSearchVisible) { // Выполняем только при открытом поиске
      handleFilterChange();
    }
  }, [selectedStatus]);


  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await fetch('https://api.aniplay.fun/api/user/profile', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.status === 401) {
          navigate('/auth');
          return;
        }

        if (!response.ok) {
          throw new Error('Ошибка при получении данных пользователя');
        }

        const data = await response.json();
        setProfileData(data);
        setAvatar(data.avatar); // Store the avatar URL
      } catch (err) {
        console.error(err.message);
      }
    };

    fetchProfile();
  }, [navigate]);


  return (
    <div className='header_container'>
      <div className='header'>
        <div className='header_part_center'>
          <div className='header_part'>
            <Link to='/welcome' className={activePath === '/welcome' ? 'header_part_active' : ''}>
              <span>Главная</span>
            </Link>
            <Link to='/favorites' className={activePath === '/favorites' ? 'header_part_active' : ''}>
              <span>Колекция</span>
            </Link>
          </div>
        </div>

        {/* Блок профиля с обработчиком клика */}
        <div className='header_part_right'>
          <div className='header_part_search' onClick={handleSearchClick}>
              <svg
                width="22px"
                height="22px"
                viewBox="0 0 24 24"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                className="styles_icon__DeOe7 SearchButton_search-icon__UU_vT"
                data-tid="Search"
                style={{ cursor: 'pointer' }}
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16.4 11a5.9 5.9 0 1 1-11.8 0 5.9 5.9 0 0 1 11.8 0Zm-1.044 6.977a8.5 8.5 0 1 1 2.121-2.121l4.084 4.083-2.122 2.122-4.083-4.084Z"
                  fill="#bbb"
                />
              </svg>
          </div>
          {isAuthenticated && (
            <>
              <div className='header_part_notifications'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="#a5a4a4" width="22" height="22" viewBox="0 0 512 512">
                  <path d="M440.08,341.31c-1.66-2-3.29-4-4.89-5.93-22-26.61-35.31-42.67-35.31-118,0-39-9.33-71-27.72-95-13.56-17.73-31.89-31.18-56.05-41.12a3,3,0,0,1-.82-.67C306.6,51.49,282.82,32,256,32s-50.59,19.49-59.28,48.56a3.13,3.13,0,0,1-.81.65c-56.38,23.21-83.78,67.74-83.78,136.14,0,75.36-13.29,91.42-35.31,118-1.6,1.93-3.23,3.89-4.89,5.93a35.16,35.16,0,0,0-4.65,37.62c6.17,13,19.32,21.07,34.33,21.07H410.5c14.94,0,28-8.06,34.19-21A35.17,35.17,0,0,0,440.08,341.31Z"/>
                  <path d="M256,480a80.06,80.06,0,0,0,70.44-42.13,4,4,0,0,0-3.54-5.87H189.12a4,4,0,0,0-3.55,5.87A80.06,80.06,0,0,0,256,480Z"/>
                </svg>
              </div>
              <a href='/settings'>
                <div className='header_part_settings'>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="#a5a4a4" width="23" height="23" viewBox="0 0 48 48" id="Layer_2" data-name="Layer 2">
                    <path class="cls-1" d="M39.23,26a16.52,16.52,0,0,0,.14-2,16.52,16.52,0,0,0-.14-2l4.33-3.39a1,1,0,0,0,.25-1.31l-4.1-7.11a1,1,0,0,0-1.25-.44l-5.11,2.06a15.68,15.68,0,0,0-3.46-2l-.77-5.43a1,1,0,0,0-1-.86H19.9a1,1,0,0,0-1,.86l-.77,5.43a15.36,15.36,0,0,0-3.46,2L9.54,9.75a1,1,0,0,0-1.25.44L4.19,17.3a1,1,0,0,0,.25,1.31L8.76,22a16.66,16.66,0,0,0-.14,2,16.52,16.52,0,0,0,.14,2L4.44,29.39a1,1,0,0,0-.25,1.31l4.1,7.11a1,1,0,0,0,1.25.44l5.11-2.06a15.68,15.68,0,0,0,3.46,2l.77,5.43a1,1,0,0,0,1,.86h8.2a1,1,0,0,0,1-.86l.77-5.43a15.36,15.36,0,0,0,3.46-2l5.11,2.06a1,1,0,0,0,1.25-.44l4.1-7.11a1,1,0,0,0-.25-1.31ZM24,31.18A7.18,7.18,0,1,1,31.17,24,7.17,7.17,0,0,1,24,31.18Z"/>
                  </svg>
                </div>
              </a>
            </>
          )}
          <div 
            className='header_profile' 
            ref={profileRef} 
            style={{ cursor: 'pointer' }}
          >
            {isAuthenticated ? (
              // Если пользователь авторизован, добавляем ссылку
              <a 
                href="/profile" 
                className="user_avatar" 
                style={{ filter: `drop-shadow(0px 0px 10px ${profileData?.mainColor || ''})` }}
              >
                <img src={avatar}  />
              </a>
            ) : (
              // Если пользователь не авторизован, добавляем обработчик навигации
              <div className="user_avatar fade_user_icon" onClick={() => navigate('/auth')}>
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  fill="#bbb" 
                  version="1.1" 
                  id="Layer_1" 
                  width="20px" 
                  height="20px" 
                  viewBox="796 796 200 200" 
                  enableBackground="new 796 796 200 200"
                >
                  <g>
                    <g>
                      <path d="M987.675,969.676c-6.521-22.754-21.304-41.99-40.878-54.33c-3.862-2.435-8.873-2.039-12.299,0.979c-10.504,9.251-23.506,14.76-37.618,14.76c-14.391,0-27.624-5.723-38.236-15.304c-3.388-3.058-8.374-3.519-12.269-1.14c-20.15,12.303-35.401,31.837-42.052,55.035c-1.787,6.223-0.531,12.933,3.364,18.109c3.905,5.169,10.005,8.215,16.48,8.215h143.661c6.48,0,12.582-3.034,16.486-8.215C988.216,982.608,989.462,975.906,987.675,969.676z" />
                      <path d="M948.091,856.479c0,33.403-22.929,60.484-51.211,60.484c-28.28,0-51.206-27.081-51.206-60.484c0-33.406,22.925-60.479,51.206-60.479C925.162,796,948.091,823.073,948.091,856.479z" />
                    </g>
                  </g>
                </svg>
              </div>
            )}
          </div>
        </div>

        {isSearchVisible && (
          <div
            className={`header_search_background_all ${isClosing ? 'fade-out' : 'visible'}`}
            ref={headerSearchBackgroundRef} // Применяем ref к контейнеру
          >
            <div className='header_search_background'>
              <input
                className="search_anime_input"
                placeholder="Введите название аниме..."
                value={searchQuery}
                onChange={handleInputChange}
              />
              <div className='search_anime_result'>
              {searchResults.length > 0 && (
                <div>
                  {searchResults.map((anime) => (
                    <a href={`/anime/${anime.id}`} key={anime.id} className='search_element'>
                      <div className='search_poster' style={{ backgroundImage: `url(${anime.poster?.originalUrl || ''})` }} />
                      <div className='search_body'>
                        <div style={{ display: 'block' }}>
                          <div className="search_title">{anime.russian}</div>
                          <div style={{display: 'flex', alignItems: 'center'}}>
                            <div className='search_eposode'>
                              {anime.episodesAired} эп
                            </div>
                            <div>·</div>
                            <div className={`search_rank_anime ${anime.score <= 7 ? 'search_rank_anime_high_score' : 'search_rank_anime_low_score'}`}>
                              {anime.score}
                            </div>
                          </div>
                          <div className="search_date" style={{ display: 'flex' }}>
                            {kindTranslations[anime.kind] || anime.kind}
                            <div style={{ padding: '0 5px' }}>·</div>
                            {anime.airedOn.year}
                          </div>
                        </div>
                      </div>
                    </a>
                  ))}
                </div>
              )}
              </div>
              <div className='search_anime_filter'>
                <div className='name_search_anime_filter'>
                  <p>Фильтры</p>
                  {isFilterVisible && (
                    <div className='container_search_anime_filter'>
                      <div className='line_container_search_anime_filter'></div>
                      <div className='block_container_search_anime_filter'>
                        <span className='title_block_container_search_anime_filter'>Статус</span>
                        <div className='container_check_box'>
                          <label className='check_box'>
                            <input
                              type='checkbox'
                              value="Анонсировано"
                              checked={selectedStatus === 'Анонсировано'}
                              onChange={() => handleStatusChange('Анонсировано')}
                            />
                            <p className='name_checkbox'>Анонсировано</p>
                          </label>
                          <label className='check_box'>
                            <input
                              type='checkbox'
                              value="Сейчас выходит"
                              checked={selectedStatus === 'Сейчас выходит'}
                              onChange={() => handleStatusChange('Сейчас выходит')}
                            />
                            <p className='name_checkbox'>Сейчас выходит</p>
                          </label>
                          <label className='check_box'>
                            <input
                              type='checkbox'
                              value="Вышедшее"
                              checked={selectedStatus === 'Вышедшее'}
                              onChange={() => handleStatusChange('Вышедшее')}
                            />
                            <p className='name_checkbox'>Вышедшее</p>
                          </label>
                        </div>
                      </div>
                      <div className='block_container_search_anime_filter'>
                        <span className='title_block_container_search_anime_filter'>Тип</span>
                        <div className='main_container_check_box'>
                          <div className='container_check_box'>
                            <label className='check_box'>
                              <input
                                type='checkbox'
                                checked={selectedType === 'TV Сериал'}
                                onChange={() => handleTypeChange('TV Сериал')}
                              />
                              <p className='name_checkbox'>TV Сериал</p>
                            </label>
                            <label className='check_box'>
                              <input
                                type='checkbox'
                                checked={selectedType === 'Фильм'}
                                onChange={() => handleTypeChange('Фильм')}
                              />
                              <p className='name_checkbox'>Фильм</p>
                            </label>
                            <label className='check_box'>
                              <input
                                type='checkbox'
                                checked={selectedType === 'OVA'}
                                onChange={() => handleTypeChange('OVA')}
                              />
                              <p className='name_checkbox'>OVA</p>
                            </label>
                          </div>
                          <div className='container_check_box'>
                            <label className='check_box'>
                              <input
                                type='checkbox'
                                checked={selectedType === 'ONA'}
                                onChange={() => handleTypeChange('ONA')}
                              />
                              <p className='name_checkbox'>ONA</p>
                            </label>
                            <label className='check_box'>
                              <input
                                type='checkbox'
                                checked={selectedType === 'Спецвыпуск'}
                                onChange={() => handleTypeChange('Спецвыпуск')}
                              />
                              <p className='name_checkbox'>Спецвыпуск</p>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className='block_container_search_anime_filter'>
                        <span className='title_block_container_search_anime_filter'>Сортировка</span>
                        <div className='container_check_box'>
                          <label className='check_box'>
                            <input
                              type='checkbox'
                              checked={selectedSorting === 'По рейтингу'}
                              onChange={() => handleSortingChange('По рейтингу')}
                            />
                            <p className='name_checkbox'>По рейтингу</p>
                          </label>
                          <label className='check_box'>
                            <input
                              type='checkbox'
                              checked={selectedSorting === 'По популярности'}
                              onChange={() => handleSortingChange('По популярности')}
                            />
                            <p className='name_checkbox'>По популярности</p>
                          </label>
                          <label className='check_box'>
                            <input
                              type='checkbox'
                              checked={selectedSorting === 'По дате выхода'}
                              onChange={() => handleSortingChange('По дате выхода')}
                            />
                            <p className='name_checkbox'>По дате выхода</p>
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* <div className='search_anime_filter_switch' onClick={toggleFilter}>
                    {isFilterVisible ? (
                      <>
                        Скрыть
                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 16 16" fill="none">
                          <path d="M8.00003 7.82842L12.5858 12.4142L15.4142 9.58578L8.00003 2.17157L0.585815 9.58578L3.41424 12.4142L8.00003 7.82842Z" fill="#ffff"/>
                        </svg>
                      </>
                    ) : ( 
                      <>
                        Показать
                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 16 16" fill="none">
                          <path d="M8.00003 8.1716L3.41424 3.58582L0.585815 6.41424L8.00003 13.8285L15.4142 6.41424L12.5858 3.58582L8.00003 8.1716Z" fill="#ffff"/>
                        </svg>
                      </>
                    )}
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
